<template>
  <div class="fill-height">
    <!--    <v-fade-transition>-->
    <router-view/>

    <!--    </v-fade-transition>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loader: false
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>